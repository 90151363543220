import { Injectable } from '@angular/core';
import { Ranking } from './ranking';
import { MocksService } from '../gepard/mocks/mocks.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RankingsService {

  rankings: Ranking[] = [];

  constructor(
    private mocks: MocksService
  ) {
  }

  requestRankings(): Observable<Ranking[]> {
    return this.mocks.get('rankings/mc2021.json').pipe(
      map(response => JSON.parse(response))
    );
  }
}
